import Container from "react-bootstrap/Container";
import { Eventbrite } from "./Eventbrite";

function Entry() {
  return (
    <>
      <Container className="content">
        <h1 className="padding-spacing">HOW TO ENTER</h1>
        <p>
          Whether you are a novice, a seasoned runner or anywhere in-between,
          you are welcome to join the ultra race. There are no entry
          requirements other than that you must be over twenty years old and up
          for giving the race your best effort. Before deciding whether you
          would like to enter, please ensure you have:
        </p>
        <ul>
          <li>
            Read the <a href="/pre-race-notes">Route Information</a>
          </li>
          <li>
            Read the <a href="/pre-race-notes">Pre-Race Notes</a>
          </li>
          <li>
            Read the <a href="/rules">Rules</a>
          </li>
          <li>
            Read the <a href="/terms-conditions">Terms & Conditions</a>
          </li>
        </ul>

        <h3 className="padding-spacing">PRICING & REFUND INFORMATION</h3>
        <p>
          Supported: £80, Unsupported: £100, including booking fees if
          applicable. (Please add £2 extra if unattached to a UK club.)
        </p>
        <p>
          Fees from successful applicants will become due upon acceptance via
          the booking system
        </p>
        <ul>
          <li>
            Cancellations before 31st May 2025 will receive a full refund.
          </li>
          <li>
            A 75% refund will be paid if the organiser is notified between 1st
            and 30th June 2025.
          </li>
          <li>
            A 50% refund will be paid if the organiser is notified between 1st
            and 31st July 2025. No refunds of the entry fee will be made after
            the 31st July 2025.
          </li>
        </ul>
        <p>
          To cancel a place, message cheshire100nw@gmail.com as soon as the
          decision is made.
        </p>

        <p>
          If more than 180 days have passed since the original card transaction,
          we will make any refund due via bank transfer to the details provided;
          in this case make sure to include bank account number and sort code
          details.
        </p>

        <p>
          N.B. If the organiser cancels the event due to unforseen circumstances
          a full refund will be offered or a transfer to the rescheduled event.
        </p>

        <h3 className="padding-spacing">PURCHASE A TICKET</h3>
        <p>
          Ready to join the race? Great! Simply purchase a ticket using the
          Eventbrite portal below, or{" "}
          <a
            href="https://www.eventbrite.co.uk/e/the-cheshire-100-ultra-run-tickets-797260085377"
            target="_blank"
          >
            click here
          </a>{" "}
          to be taken to our Eventbrite page.
        </p>
        <Eventbrite />
      </Container>
    </>
  );
}
export { Entry };
