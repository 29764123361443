import React from 'react';
import Container from 'react-bootstrap/Container';
import { AiFillFacebook } from "react-icons/ai"
import {IoLogoWhatsapp} from "react-icons/io"
import {AiFillPhone} from "react-icons/ai"
import {BsFillPinMapFill} from "react-icons/bs"
import {BsFillEnvelopeFill} from "react-icons/bs"

function Footer() {
  return (
    <>
        <div className='footer-container'>
        <Container>
        <footer class="footer">
        <div class="footer-left col-md-4 col-sm-6">
            <p class="about">
            <span> About the company</span> This race has been organised by New Era Community boxing which is registered as a community interest company, based in Northwich, Cheshire. The club has been offering training and support for young people across the area since 2011.
            </p>
            <div class="icons">
            <a href="#" target="_blank"><AiFillFacebook className='footer-icon' /></a>
            <a href="#" target="_blank"><IoLogoWhatsapp className='footer-icon' /></a>
            </div>
        </div>
        <div class="footer-center col-md-4 col-sm-6">
            <div className="contact-info">
            <BsFillPinMapFill className='footer-icon' />#
            <p><span> Unit 7b Leftwich Warehouse,</span>Queen Street, Northwich, CW8 1BZ</p>
            </div>
            <div className="contact-info">
            <AiFillPhone className='footer-icon' />#
            <p>07830 085132</p>
            </div>
            <div className="contact-info">
            <BsFillEnvelopeFill className='footer-icon' />#
            <p><a href= "mailto: cheshire100nw@gmail.com">cheshire100nw@gmail.com</a></p>
            </div>
        </div>
        <div class="footer-right col-md-4 col-sm-6">
            <h2 style={{fontWeight:"bold", fontSize:"24px"}}>THE CHESHIRE 100 ULTRA RUN</h2>
            {/* <p class="menu">
            <a href="#"> Home</a> |
            <a href="#">  About</a> |
            <a href="#">  Services</a> |
            <a href="#">  Portfolio</a> |
            <a href="#">  News</a> |
            <a href="#">  Contact</a>
            </p> */}
            <p class="name">NEW ERA BOXING C.I.C. is a registered community interest company (07810483)</p>
            <p class="about">Website designed by Chris de la Bastide (For web design queries please email chrisdelabastide@outlook.com)</p>
        </div>
        </footer>
        
</Container>
</div>
    </>
  );
}

export { Footer };