import Container from "react-bootstrap/Container";
import Accordion from "react-bootstrap/Accordion";

function PreRaceNotes() {
  return (
    <>
      <Container className="hero-img-container">
        <div className="padding-spacing"></div>
        <div class="hero-image notes-hero-image">
          <div className="main-container">
            <div className="content-container">
              <div className="hero-sub-images">
                <div className="hero-text">
                  <h1>PRE-RACE NOTES</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <h1>PRE-RACE NOTES</h1> */}
        <p className="padding-spacing">
          Thank you for showing interest in the Cheshire 100 Ultra Run. Our
          pre-race notes were written to help you make the decision of taking
          part. Please ensure you read all of this information before purchasing
          tickets for the event.
        </p>
      </Container>
      <Container>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>ENTRIES</Accordion.Header>
            <Accordion.Body>
              Before entering the Cheshire 100, it is important to understand
              the nature of this race. Therefore, we have included some pre-race
              questions that you must complete upon registration.
              <br></br>
              <br></br>
              Upon registration you will also receive all the guidance
              information, terms and conditions and pre entry notes that you
              must read and understand. An entry list will be uploaded to
              this website and there you will be able to see fellow
              entrants once payment has been confirmed.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>FEES</Accordion.Header>
            <Accordion.Body>
              Payment of fees will be due immediately upon entry and
              unfortunately spaces cannot be held.
              <br></br>
              <br></br>
              Cancellations before 31st May 2025 will receive a full refund.
              <br></br>
              <br></br>A 75% refund will be paid if the organiser is notified
              between 1st and 30th June 2025.
              <br></br>
              <br></br>A 50% refund will be paid if the organiser is notified
              between 1st and 31st July 2025.
              <br></br>
              <br></br>
              No refunds of the entry fee will be made after the 31st July 2025.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>BUDDY RUNNERS</Accordion.Header>
            <Accordion.Body>
              Solo buddy runners are permitted for Supported runners from CP3
              onwards.
              <br></br>
              <br></br>
              Buddies will be instructed to run in single file whilst supporting
              their runner.
              <br></br>
              <br></br>
              If in front buddies will be regarded as pacers and pacing is not
              permitted.
              <br></br>
              <br></br>
              Both supported and supported runners may have solo buddy runners,
              but the organiser accepts no responsibility for the buddy’s
              welfare or transport if they get into difficulty.
              <br></br>
              <br></br>
              Support crews or buddy runners cannot be bicycle mounted.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>RETIREMENT</Accordion.Header>
            <Accordion.Body>
              Every runner has a responsibility toward other entrants and, with
              limited back-up, we cannot allow too much ‘race spread’. If you
              realise you are not going to reach a checkpoint by its closing
              ‘cut-off’ time, telephone Race HQ.
              <br></br>
              <br></br>
              Any runner arriving at a checkpoint after its official ‘cut-off’
              time (which is not necessarily what the runner’s watch or mobile
              may show) will be withdrawn.
              <br></br>
              <br></br>
              UPON WITHDRAWAL FROM THE RACE, RUNNERS MUST REPORT THEIR
              RETIREMENT
              <br></br>
              <br></br>
              DIRECTLY TO RACE HQ BY TELEPHONE.
              <br></br>
              <br></br>
              EVEN IF YOU STOP AT A CHECKPOINT AND HAVE NOTIFIED MARSHALS OF
              YOUR WITHDRAWAL, YOU MUST STILL NOTIFY RACE HQ.
              <br></br>
              <br></br>
              IF THE RACE HQ NUMBER IS BUSY, YOU SHOULD RE-ATTEMPT UNTIL YOU
              HAVE SPOKEN WITH US.
              <br></br>
              <br></br>
              <span className="bold-text">
                Only very urgent calls should be made directly to the Race
                Organiser number.
              </span>{" "}
              Both phone numbers will be printed on your race number and
              transfer charge calls will be accepted. To be repaid afterwards,
              please.
              <br></br>
              <br></br>
              Transport to the nearest station, or to the Finish (eventually),
              will be provided for unsupported retirees.
              <br></br>
              <br></br>
              The overall time limit is 36 hours, starting at 6:00am on Saturday
              the 20th of September to finish by 18:00pm on Sunday 21st of
              September.
              <br></br>
              <br></br>
              The maximum permitted time stopped at any one time is 36 minutes.
              This is to make the point that this is a non-stop race rather than
              a series of ‘short’ runs with rest breaks.
              <br></br>
              <br></br>
              (The fastest overall times are achieved by those who waste the
              least time hanging around or sleeping at checkpoints.)
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>RACE NUMBERS</Accordion.Header>
            <Accordion.Body>
              Please write any medical information on the rear of your race
              number. Wear you race number prominently, on your front,
              uncovered, and full size throughout the race.
              <br></br>
              <br></br>
              For your safety we have to know which checkpoints you have passed.
              The race number identifies you from any other casual runners on
              the canal path and indicates to other towpath users that you are
              taking part in an official event.
              <br></br>
              <br></br>
              The race number also provides anyone unfortunate enough to stumble
              across any injured or unconscious runner emergency telephone
              numbers to contact.
              <br></br>
              <br></br>
              We will supply you with two race numbers. What you do with the
              second number is up to you. If you do not intend to wear your
              number properly, please do not enter.
              <br></br>
              <br></br>
              We will be brutal about disqualifying anyone not showing their
              race number on their front, at all times, including the finish –
              even those who have competed/completed ultra events before.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <Accordion.Header>CHECKPOINTS, DRINKS/FOOD & BAGS</Accordion.Header>
            <Accordion.Body>
              Nearer to the event we will provide details of what each
              checkpoint will provide in terms of refreshments.
              <br></br>
              <br></br>
              Hot food & snacks for Unsupported runners will be at the
              checkpoint sites indicated above in bold.
              <br></br>
              <br></br>
              We aim to have Unsupported runners’ ‘drop bag’ (maximum weight
              15kgs) available at all checkpoints.
              <br></br>
              <br></br>
              The event staff will make a note of your physical condition and
              well being at every checkpoint.
              <br></br>
              <br></br>
              Please be honest at checkpoints with the event staff, they are
              there to help.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="6">
            <Accordion.Header>CREWS</Accordion.Header>
            <Accordion.Body>
              Crews are reminded that throughout the route to consider noise
              levels and respect local residents.
              <br></br>
              <br></br>
              Any anti social behaviour by crews may result in the runner being
              disqualified. Crews must not run vehicle engines needlessly.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="7">
            <Accordion.Header>MANDATORY ITEMS</Accordion.Header>
            <Accordion.Body>
              Unsupported runners must carry the provided route maps in order to
              pin-point their location should our transport need to collect them
              upon retirement.
              <br></br>
              <br></br>
              All runners are required to carry a foil type ‘space’ blanket at
              all times.
              <br></br>
              <br></br>
              All runners are required to carry a mobile phone – charged, with
              sufficient credit, and{" "}
              <span className="bold-text">switched on</span> at all times.
              <br></br>
              <br></br>
              At night, all runners and buddies are required to carry a torch or
              headlamp, with spare batteries, and wear reflective clothing when
              on road sections.
              <br></br>
              <br></br>
              All runners and buddies must carry their own cup for accessing hot
              and cold drinks at aid stations.
              <br></br>
              <br></br>
              There may be kit checks at race registration and at checkpoints to
              ensure compliance.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="8">
            <Accordion.Header>OTHER INFORMATION</Accordion.Header>
            <Accordion.Body>
              Before the race commencement, full race information, will be sent
              by email or post as appropriate including:
              <br></br>
              <br></br>· Course maps, route description and any alterations
              including path and bridge closures including anything else
              affecting the route.
              <br></br>· Shirt Order Instructions. Shirts and Hoodies for both
              runners and crews will be available to order.
              <br></br>· Advice for Support Crews
              <br></br>· List of possible meeting points and a route
              description. Please give these to your crew well in advance of the
              day.
              <br></br>· Checkpoint Details including closing cut-off times.
              <br></br>· Race Numbers
              <br></br>
              <br></br>
              Keys are required for most Canal and River Trust (formerly British
              Waterways) facilities.
              <br></br>
              <br></br>
              If you would like a key for the toilets and water points, either
              for yourself or for your support crew members, they can be
              purchased at Friday night Early Registration and on race day on
              payment of a £10 deposit per key.
              <br></br>
              <br></br>
              To comply with UK:Athletics rules, we are obliged to add a levy to
              the entry fee if you are not an affiliated club member.
              <br></br>
              <br></br>
              (To avoid paying extra at this, and many other trail races,
              consider joining the TRA http://www.tra-uk.org/membership the
              National body supporting race organisers.)
              <br></br>
              <br></br>
              Unsupported runners should think about their homeward journey as
              we can only guarantee transport to the nearest station or to the
              Finish. Of course, we will try to arrange further help and lifts
              if at all possible.
              <br></br>
              <br></br>
              Please be courteous to other towpath users as we dare not lose the
              goodwill of the Canal and River Trust.
              <br></br>
              <br></br>A final reminder that your health and safety throughout
              is paramount and care should be taken whilst racing.
              <br></br>
              <br></br>
              Reading through this, we realise that a lot of it sounds very
              straight and serious.
              <br></br>
              <br></br>
              We would like to add that this is a light-hearted and friendly
              event (except for those who won’t wear numbers properly), which we
              are sure you will enjoy. Please feel free to email Mark on
              cheshire100nw@gmail.com or phone 07830085132 if you have any
              queries.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Container>
    </>
  );
}

export { PreRaceNotes };
