import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import CardGroup from "react-bootstrap/CardGroup";

function HomeCards() {
  return (
    <>
      <Container fluid="lg">
        <CardGroup className="CardGroup padding-spacing">
          <Card style={{ width: "100%" }}>
            <Card.Body>
              <Card.Title>ABOUT THE RACE</Card.Title>
              <Card.Text>
                <p>
                  The Cheshire 100 is an exciting new ultra-distance race which
                  covers the heart of Cheshire including Manchester. This is a
                  100 mile continuous race which starts in Northwich, Cheshire
                  and covers the traditional 'Cheshire ring' canal route in a
                  clockwise direction. Navigating along no less than 6 different
                  canals the route is scenic, easy to follow and relatively
                  flat.
                </p>
                <p>
                  This race has been organised by New Era Community boxing which
                  is registered as a community interest company, based in
                  Northwich, Cheshire. The club has been offering training and
                  support for young people across the area since 2011.
                </p>
                <p>
                  The race is inspired by the Cheshire canal ring route, which
                  the race follows along. Mark, the lead coach from New Era
                  Community boxing completed the route in 2020 and in doing so
                  raised money for Cheshire young carers.
                </p>
                <p>
                  Since completing the route, it has been our ambition to
                  promote the Cheshire canal ring with this event offering the
                  opportunity to take part in a exciting, affordable ultra
                  running event.
                </p>
                <p></p>
              </Card.Text>
              <Button
                href="/route-information"
                variant="primary"
                className="button"
              >
                Find out more
              </Button>
            </Card.Body>
          </Card>

          <Card style={{ width: "100%" }}>
            <Card.Body>
              <Card.Title>PRICING</Card.Title>
              <Card.Text>
                <p>
                  Supported: £80, Unsupported: £100, including booking fees if
                  applicable. (Please add £2 extra if unattached to a UK club.)
                </p>
                <p>
                  Fees from successful applicants will become due upon
                  acceptance via the booking system
                </p>
                <ul>
                  <li>
                    Cancellations before 31st May 2025 will receive a full
                    refund.
                  </li>
                  <li>
                    A 75% refund will be paid if the organiser is notified
                    between 1st and 30th June 2025.
                  </li>
                  <li>
                    A 50% refund will be paid if the organiser is notified
                    between 1st and 31st July 2025. No refunds of the entry fee
                    will be made after the 31st July 2025.
                  </li>
                </ul>
                <p>
                  To cancel a place, message cheshire100nw@gmail.com as soon as
                  the decision is made.
                </p>

                <p>
                  If more than 180 days have passed since the original card
                  transaction, we will make any refund due via bank transfer to
                  the details provided; in this case make sure to include bank
                  account number and sort code details.
                </p>

                <p>
                  N.B. If the organiser cancels the event due to unforseen
                  circumstances a full refund will be offered or a transfer to
                  the rescheduled event.
                </p>
              </Card.Text>
            </Card.Body>
          </Card>
        </CardGroup>
        <CardGroup className="CardGroup padding-spacing">
          <Card style={{ width: "100%" }}>
            {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
            <Card.Body>
              <Card.Title>BENEFITS OF ENTERING THE CHESHIRE 100</Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    Easy route navigation – detailed maps and clear pre-race
                    guidance provided.
                  </li>
                  <li>Pre-race advice and support for every participant.</li>
                  <li>
                    Plenty of support on route – snacks and drinks at every
                    checkpoint.
                  </li>
                  <li>
                    An accessible route for support teams and buddy runners
                    throughout.
                  </li>
                  <li>
                    Transport for retirees – a lift to the nearest station
                    available for anyone having a bad day.
                  </li>
                  <li>
                    Easy to meet cut-off times – simply in line with the
                    generous overall time limits.
                  </li>
                  <li>
                    No qualification – previous experience of just a marathon
                    has proven enough.
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card style={{ width: "100%" }}>
            <Card.Body>
              <Card.Title>HOW DO I JOIN?</Card.Title>
              <Card.Text>
                Whether you are a novice, a seasoned runner or anywhere
                in-between, you are welcome to join the ultra race. There are no
                entry requirements other than that you must be over twenty years
                old and up for giving the race your best effort. Before deciding
                whether you would like to enter, please ensure you have:
                <br></br>
                <br></br>
                <ul>
                  <li>
                    Read the <a href="/pre-race-notes">Route Information</a>
                  </li>
                  <li>
                    Read the <a href="/pre-race-notes">Pre-Race Notes</a>
                  </li>
                  <li>
                    Read the <a href="/rules">Rules</a>
                  </li>
                  <li>
                    Read the <a href="/terms-conditions">Terms & Conditions</a>
                  </li>
                </ul>
                Ready to join the race? Great! Simply click the button below or
                scroll to the bottom of this page to purchase a ticket for the
                event.
              </Card.Text>
              <Button variant="primary" href="/how-to-enter" className="button">
                How to enter
              </Button>
            </Card.Body>
          </Card>
        </CardGroup>
      </Container>
    </>
  );
}

export default HomeCards;
